<template>
  <LoadingData v-if="loadData" />
  <div v-else class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link to="/financials"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-chart-line me-2" /> Histórico de Correção
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Histórico de Correção</h6>
          <p>Exibe os índices mensais utilizados na correção anual</p>
        </template>
      </Popper>
    </div>
    <div class="card-body p-0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Data do Reajuste</th>
            <th>Porcentagem do Reajuste</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ readjustment.ReajustDate1 }}</td>
            <td>{{ readjustment.Percent1 }}</td>
          </tr>
          <tr>
            <td>{{ readjustment.ReajustDate2 }}</td>
            <td>{{ readjustment.Percent2 }}</td>
          </tr>
          <tr>
            <td>{{ readjustment.ReajustDate3 }}</td>
            <td>{{ readjustment.Percent3 }}</td>
          </tr>
          <tr v-if="readjustments">
            <td colspan="3" class="text-center">
              Histórico de reajuste não localizado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import Popper from 'vue3-popper'

export default {
  name: 'Statements',

  components: {
    LoadingData,
    Popper,
  },

  data() {
    return {
      loadData: true,
      readjustment: null,
      unity: this.$store.getters.unity,
    }
  },

  methods: {
    async getReadjustments() {
      try {
        this.readjustment = await this.$store.dispatch(
          'getFinancialReadjustments',
          this.unity,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getReadjustments()
  },
}
</script>
