import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName !== null && userName !== ''
    ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}`
    : '-'
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge bg-danger">Inativo</span>'
    case 1:
      return '<span class="badge bg-success">Ativo</span>'
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

const statusStatementsTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge bg-warning">Em Aberto</span>'
    case 1:
      return '<span class="badge bg-success">Baixado</span>'
    default:
      return '<span class="badge bg-info">Parcialmente Pago</span>'
  }
}

export { createdUpdatedUser, statusTranslate, statusStatementsTranslate }
